// import logo from "./logo.svg";
import header from "./Header.svg";
import header_small from "./Header_small.svg";
import "./App.css";
import useWindowDimensions from "./useWindowDimensions";

function App() {
  const width = useWindowDimensions();
  console.log("width", width);
  return (
    <div className="App">
      <header className="App-header">
        <div className="App">
          {width > 600 ? (
            <img src={header} className="App-logo" alt="logo" />
          ) : (
            <img src={header_small} className="App-logo" alt="logo" />
          )}
          {/* creta a box wehr the description of the app will be */}
          <div className="App-description">
            <div className="App-text">
              <h1>DeepGolf</h1>
              <p>
                DeepGolf is developing a new way of learning to play golf. With
                direct and simple feedback, understand what mistakes you are
                making in your golf game. Anywhere and anytime.
              </p>
              <p>
                If you want to be part of the development, you can sign up as a
                beta tester or follow us via newsletter or social media.
              </p>
              <div className="App-social">
                <a
                  className="Link-hightlight"
                  href="https://forms.gle/Kuu66MVBDGbcZ81x8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Beta Test & Newsletter
                </a>
                <a
                  href="https://www.instagram.com/_deepgolf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
                <a
                  href="https://www.linkedin.com/company/deepgolf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
                {/* Mail */}
                <a href="mailto:hallo@deep-golf.de">E-Mail</a>
              </div>
            </div>
          </div>
        </div>

        {/* <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
    </div>
  );
}

export default App;
